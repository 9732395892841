import React from 'react';
import Select, { SelectOptions, SelectProps } from '../Select';
import { useTranslation } from '@external/react-i18next';
import { WeekdayFull } from '@typings/operations';

type WeekdaySelectProps = Omit<SelectProps, 'options'>;

const WeekdaySelect: React.FC<WeekdaySelectProps> = props => {
  const { t } = useTranslation();
  const weekdaySelectOptions: SelectOptions = [
    {
      label: t('global.weekdayfull.none', 'Please Select'),
      value: '',
    },
    {
      label: t('global.weekdayfull.monday', 'Monday'),
      value: WeekdayFull.Monday,
    },
    {
      label: t('global.weekdayfull.tuesday', 'Tuesday'),
      value: WeekdayFull.Tuesday,
    },
    {
      label: t('global.weekdayfull.wednesday', 'Wednesday'),
      value: WeekdayFull.Wednesday,
    },
    {
      label: t('global.weekdayfull.thursday', 'Thursday'),
      value: WeekdayFull.Thursday,
    },
    {
      label: t('global.weekdayfull.friday', 'Friday'),
      value: WeekdayFull.Friday,
    },
    {
      label: t('global.weekdayfull.saturday', 'Saturday'),
      value: WeekdayFull.Saturday,
    },
    {
      label: t('global.weekdayfull.sunday', 'Sunday'),
      value: WeekdayFull.Sunday,
    },
  ];

  return <Select options={weekdaySelectOptions} {...props} />;
};

export default WeekdaySelect;
