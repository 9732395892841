// Libs
import * as yup from 'yup';
import { TFunction } from 'i18next';

// Utils
import {
  bindTFuncToSchemaField,
  hasMaxLength,
  isRequired,
  isURL,
} from '@components/Formik/validation/schemaDefinitions';
import { zipCodeIsRequired } from '@utils/validation';

// Types
import { MeetingType } from '@typings/operations';

export default (t: TFunction) => {
  const s = bindTFuncToSchemaField(t);
  return yup.object().shape({
    name: s(yup.string(), hasMaxLength(120)),
    languageId: s(yup.string(), isRequired),
    comment: s(yup.string(), hasMaxLength(250)),
    weekday: s(yup.string().nullable()).when('type', {
      is: type => type === MeetingType.Physical || type === MeetingType.Hybrid,
      then: s(
        yup
          .string()
          .nullable()
          .required(t('form.field.error_required', 'Required'))
      ),
      otherwise: s(yup.string().nullable()).test(
        'weekday-or-time-required',
        t('form.field.error_required', 'Required'),
        function(value) {
          const { time, type } = this.parent;
          if (type === MeetingType.Online) {
            if ((time && !value) || (!time && value)) {
              return this.createError({
                message: t('form.field.error_required', 'Required'),
              });
            }
          }
          return true;
        }
      ),
    }),

    time: s(yup.string())
      .matches(
        /[0-2][0-9]:[0-5][0-9]/,
        t('form.field.error_valid_time', 'Must be valid time in format HH:mm')
      )
      .when('type', {
        is: type =>
          type === MeetingType.Physical || type === MeetingType.Hybrid,
        then: s(yup.string(), isRequired),
        otherwise: s(yup.string()).test(
          'time-or-weekday-required',
          t('form.field.error_required', 'Required'),
          function(value) {
            const { weekday, type } = this.parent;
            if (type === MeetingType.Online) {
              if ((weekday && !value) || (!weekday && value)) {
                return this.createError({
                  message: t('form.field.error_required', 'Required'),
                });
              }
            }
            return true;
          }
        ),
      }),
    frequency: s(yup.string(), hasMaxLength(150)),
    type: s(yup.string(), isRequired),
    locationVaries: s<yup.BooleanSchema>(yup.boolean()),
    locationNameOrDetail: s(yup.string(), hasMaxLength(4096)).when(
      'locationVaries',
      {
        is: true,
        then: yup.string().required(t('form.field.error_required', 'Required')),
      }
    ),
    lineOne: s(yup.string()).when(['type', 'locationVaries'], {
      is: (type, locationVaries) =>
        (type === MeetingType.Physical || type === MeetingType.Hybrid) &&
        !locationVaries,
      then: s(yup.string(), isRequired),
    }),
    lineTwo: s(yup.string(), hasMaxLength(40)),
    lineThree: s(yup.string(), hasMaxLength(40)),
    countryId: s(yup.string()).when(['type'], {
      is: type => type === MeetingType.Physical,
      then: s(yup.string(), isRequired),
    }),
    city: s(yup.string(), hasMaxLength(40)).when(['type'], {
      is: type => type === MeetingType.Physical,
      then: s(yup.string(), isRequired),
    }),
    stateId: s(yup.string()).when(['type', 'address.hasStates'], {
      is: (type, hasStates) => type === MeetingType.Physical && hasStates,
      then: s(yup.string(), isRequired),
    }),
    internationalProvince: s(yup.string().nullable()).when(
      ['type', 'hasStates'],
      {
        is: (type, hasStates) => type === MeetingType.Physical && !hasStates,
        then: s(yup.string().nullable()),
      }
    ),
    postalCode: s(yup.string()).when(['type', 'countryId'], {
      is: (type, countryId) =>
        (type === MeetingType.Physical || type === MeetingType.Hybrid) &&
        zipCodeIsRequired(countryId),
      then: s(yup.string(), isRequired),
    }),
    detailForJoiningRemotely: s(yup.string(), hasMaxLength(2048)).when('type', {
      is: type => type === MeetingType.Online || type === MeetingType.Hybrid,
      then: s(yup.string(), isRequired),
    }),
    onlineLocation: s(yup.string(), hasMaxLength(2048)).when('type', {
      is: type => type === MeetingType.Online || type === MeetingType.Hybrid,
      then: s(yup.string(), isURL, isRequired),
    }),
  });
};
